import { useEffect, useRef, useState } from 'react';

// material-ui
import { Button, Dialog, useMediaQuery } from '@mui/material';

// third-party
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';

// redux
import { useDispatch, useSelector } from 'store';

// project imports
import MainCard from 'ui/MainCard';
import SubCard from 'ui/SubCard';
import CalendarStyled from './CalendarStyled';
import Toolbar from './Toolbar';
import AddEventForm from './AddEventForm';

// assets
import AddAlarmTwoToneIcon from '@mui/icons-material/AddAlarmTwoTone';

import { useIntl } from 'react-intl'; // Import the useIntl hook
// ==============================|| APPLICATION CALENDAR ||============================== //
import useAuth from 'hooks/useAuth';

import axios from 'utils/axios';

const Calendar = () => {
  const dispatch = useDispatch();
  const calendarRef = useRef(null);
  const matchSm = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const intl = useIntl();
  const { getToken } = useAuth();

  function t(id) {
    return intl.formatMessage({ id });
  }

  // fetch events data
  const [events, setEvents] = useState([]);
  // const calendarState = useSelector((state) => state.calendar);

  // Function to set headers
  const setHeaders = async () => {
    const token = await getToken();
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
  };

  // Fetch events
  const fetchEvents = async () => {
    try {
      const headers = await setHeaders();
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/calendar/events`, headers);
      setEvents(response.data); // Assuming your API returns { events: [...] }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  // Add an event
  const addEvent = async (newEvent) => {
    try {
      const headers = await setHeaders();
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/calendar/events/new`, newEvent, headers);
      setEvents((prevEvents) => [...prevEvents, response.data]); // Add new event to state
    } catch (error) {
      console.error('Error adding event:', error);
    }
  };

  // Update an event
  const updateEvent = async (updatedEvent) => {
    try {
      const headers = await setHeaders();
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/calendar/events/update`, updatedEvent, headers);
      const updatedEvents = events.map((event) =>
        event.id === updatedEvent.eventId ? { ...event, ...updatedEvent.update } : event
      );
      setEvents(updatedEvents);

    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  // Remove an event
  const removeEvent = async (eventId) => {
    try {
      const headers = await setHeaders();
      await axios.post(`${process.env.REACT_APP_URL}/api/calendar/events/remove`, { eventId }, headers);
      handleModalClose();
      setEvents((prevEvents) => prevEvents.filter((event) => event.id !== eventId)); // Remove event from state
    } catch (error) {
      console.error('Error removing event:', error);
    }
  };

  useEffect(() => {
    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Add dispatch to dependency array


  // useEffect(() => {
  //   setEvents(calendarState.events);
  // }, [calendarState]);

  const [date, setDate] = useState(new Date());
  const [view, setView] = useState(matchSm ? 'listWeek' : 'dayGridMonth');

  // calendar toolbar events
  const handleDateToday = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleViewChange = (newView) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  // set calendar view
  useEffect(() => {
    handleViewChange(matchSm ? 'listWeek' : 'dayGridMonth');
  }, [matchSm]);

  const handleDatePrev = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  // calendar event select/add/edit/delete
  const handleRangeSelect = (arg) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }

    setSelectedRange({
      start: arg.start,
      end: arg.end
    });
    setIsModalOpen(true);
  };

  const handleEventSelect = (arg) => {
    if (arg.event.id) {
      // console.log(arg.event.id)
      const selectEvent = events.find((_event) => _event.id == arg.event.id);
      // console.log(selectEvent)
      setSelectedEvent(selectEvent);
    } else {
      setSelectedEvent(null);
    }
    setIsModalOpen(true);
  };

  const handleEventUpdate = async ({ event }) => {
    try {
      dispatch(
        updateEvent({
          eventId: event.id,
          update: {
            allDay: event.allDay,
            start: event.start,
            end: event.end
          }
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
    setSelectedRange(null);
  };

  const handleEventCreate = async (data) => {
    addEvent(data);
    handleModalClose();
  };

  const handleUpdateEvent = async (eventId, update) => {
    updateEvent({ eventId, update });
    handleModalClose();
  };

  const handleEventDelete = async (id) => {
    try {
      dispatch(removeEvent(id));
      handleModalClose();
    } catch (err) {
      console.error(err);
    }
  };

  const handleAddClick = () => {
    setIsModalOpen(true);
  };

  return (
    <MainCard
      title={t("Tasks")}
      secondary={
        <Button color="secondary" variant="contained" onClick={handleAddClick}>
          <AddAlarmTwoToneIcon fontSize="small" sx={{ mr: 0.75 }} />
          {t("Add New Task")}
        </Button>
      }
    >
      <CalendarStyled>
        <Toolbar
          date={date}
          view={view}
          onClickNext={handleDateNext}
          onClickPrev={handleDatePrev}
          onClickToday={handleDateToday}
          onChangeView={handleViewChange}
        />
        <SubCard>
          <FullCalendar
            weekends
            editable
            droppable
            selectable
            events={events}
            ref={calendarRef}
            rerenderDelay={10}
            initialDate={date}
            initialView={view}
            dayMaxEventRows={3}
            eventDisplay="block"
            headerToolbar={false}
            allDayMaintainDuration
            eventResizableFromStart
            select={handleRangeSelect}
            eventDrop={handleEventUpdate}
            eventClick={handleEventSelect}
            eventResize={handleEventUpdate}
            height={matchSm ? 'auto' : 720}
            plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
          />
        </SubCard>
      </CalendarStyled>

      {/* Dialog renders its body even if not open */}
      <Dialog maxWidth="sm" fullWidth onClose={handleModalClose} open={isModalOpen} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
        {isModalOpen && (
          <AddEventForm
            event={selectedEvent}
            range={selectedRange}
            onCancel={handleModalClose}
            handleDelete={handleEventDelete}
            handleCreate={handleEventCreate}
            handleUpdate={handleUpdateEvent}
          />
        )}
      </Dialog>
    </MainCard>
  );
};

export default Calendar;
